<template>
<div>
     <div class="mb-2 alert alert-warning">
        <h5 class="alert-heading mb-0">Your email address is not verified! </h5>
        <div class="py-3">
            <span class="mr-4 font-14">To be notified when your order is delivered, please check your email inbox and verify by clicking the link we sent you. <a @click.prevent="resentLink()" href="#" class="alert-link"> Click to resend link!</a></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:"user-email-verification",
    methods:{
        resentLink(){
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.get(`/account/resend-link`)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    Swal.fire({
                        title: "Done!",
                        text: `${response.data.success}`,
                        confirmButtonColor: "#222222",
                        confirmButtonText: "Okay!"
                    })
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=>{console.log(error)  })
        }
    }
}
</script>
